.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
#home {
  height: 31px;
  margin: 24px 0 25px;
}
.layout5 #head {
  margin-top: 200px;
}
.wrapper {
  padding-top: 80px;
}
.container--mood {
  margin-top: 20px;
  min-height: calc(100vh - 80px - 20px + 44px);
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  min-height: calc(100svh - 80px - 20px + 44px);
  /* Use vh as a fallback for browsers that do not support Custom Properties */
}
.container--mood .sitename {
  align-items: flex-start;
  padding-left: 35px;
}
.container--mood .sitename__content {
  text-align: right;
}
.container--mood .sitename__content a {
  font-size: 18px;
  line-height: 1.11111111;
}
.container--mood .content {
  padding-left: calc(var(--deskSpace) * 1 + 100px);
  padding-right: max(var(--deskSpace), calc((100vw - 1122px) / 2));
  padding-bottom: 100px;
}
.designelement--navi {
  width: 755px;
  left: -50px;
  bottom: -50px;
}
.designelement--footLeft {
  width: 787px;
  left: -22px;
  top: -320px;
}
.designelement--footRight {
  width: 687px;
  right: -5px;
  top: 140px;
}
.designelement--kontakt {
  width: 1021px;
  right: -185px;
  bottom: -200px;
}
#navilinks {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  justify-content: center;
  gap: 40px;
  margin-bottom: 40px;
}
#navilinks .meta {
  float: left;
  font-size: 26px;
  line-height: 1.46153846;
}
.cbdModule--teamTitle {
  margin-top: calc(var(--spaceTotal) * 2 + 20px);
  margin-bottom: calc(var(--spaceTotal) * 1 + 5px);
}
.cbdModule--masonryTitle {
  margin-top: calc(var(--spaceTotal) * 2 + 50px);
  margin-bottom: calc(var(--spaceTotal) * 1 + 20px);
}
.section--footer {
  margin-top: calc(var(--spaceTotal) * 2 + 20px);
}
.footarea {
  width: 102.67379679%;
  margin-left: -1.14678899%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 160px;
  margin-bottom: 40px;
}
.footpart {
  margin-left: 1.30208333%;
  margin-right: 1.30208333%;
}
.footpart.footpart--slim {
  width: 47.39583333%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
}
.footpart.footpart--wide {
  width: 97.39583333%;
  padding-left: 34.22459893%;
  padding-right: 8.55614973%;
  box-sizing: border-box;
}
.footerText {
  font-size: 24px;
  line-height: 1.33333333;
}
.getintouch {
  gap: 25px;
}
.getintouch .email,
.getintouch .meta {
  font-size: 26px;
  line-height: 1.46153846;
}
.vcard {
  font-size: 14px;
  line-height: 1.42857143;
}
#legal {
  justify-content: flex-end;
}
#legal .meta {
  font-size: 14px;
  line-height: 1.42857143;
}
.backlink {
  margin-top: calc(var(--spaceTotal) * 2 + 20px);
  margin-bottom: calc(var(--spaceTotal) * 2 + 20px);
}
h1 {
  font-size: clamp(5rem, -6.228rem + 17.54vw, 8.75rem);
}
.layout5 h1 {
  font-size: 58px;
  line-height: 1.10344828;
}
h2 {
  font-size: 58px;
  line-height: 1.10344828;
}
h3 {
  font-size: 26px;
  line-height: 1.46153846;
}
.ns-slider__quoteText,
.ns-slider__part--sliderText p,
p.loud {
  font-size: 24px;
  line-height: 1.33333333;
}
.wrapper {
  font-size: 18px;
  line-height: 1.22222222;
}
.area--one {
  width: 102.67379679%;
  smargin-left: -1.3368984%;
  margin-top: calc(var(--spaceTotal) * 1 + 20px);
  margin-bottom: calc(var(--spaceTotal) * 1 + 20px);
}
.layout4 .area--one.area1 {
  margin-top: calc(var(--spaceTotal) * 2 + 20px);
}
.area--two {
  margin-top: calc(var(--spaceTotal) * 1 + 20px);
  margin-bottom: calc(var(--spaceTotal) * 1 + 20px);
}
.area--three {
  width: 119.78609626%;
  margin-left: -9.89304813%;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--spaceTotal) * 1 + 20px);
  margin-bottom: calc(var(--spaceTotal) * 1 + 20px);
}
.area--masonry {
  width: 102.67379679%;
  margin-left: -1.3368984%;
  margin-top: calc(var(--spaceTotal) * 1 + 20px);
  margin-bottom: calc(var(--spaceTotal) * 1 + 20px);
}
#edit .area--masonry {
  display: flex;
  flex-wrap: wrap;
}
.area--intro {
  padding-left: 34.22459893%;
  box-sizing: border-box;
  margin-top: calc(var(--spaceTotal) * 1 + 20px);
  margin-bottom: calc(var(--spaceTotal) * 1 + 20px);
}
.area--one .unit {
  width: 97.39583333%;
  margin-left: 1.30208333%;
  margin-right: 1.30208333%;
}
.area--one .unit.unit--slim {
  width: 47.39583333%;
}
.area--one .unit.unit--sideimage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.area--one .unit.unit--sideimage-right {
  flex-direction: row-reverse;
}
.area--one .unit.unit--sideimage .unit__background {
  width: 48.48484848%;
}
.area--one .unit.unit--sideimage .unit__content {
  width: 40.10695187%;
}
.area--one .unit.unit--simple .part.tiny {
  padding: 0 17.96875%;
  box-sizing: border-box;
}
.area--two .unit--simple {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}
.area--two .unit--simple .unit__background {
  width: 57.9795022%;
}
.area--two .unit--simple .unit__content {
  width: 42.0204978%;
  padding-left: 7.10102489%;
  padding-right: 9.07759883%;
  box-sizing: border-box;
}
@media (max-width: 1200px) {
  .area--two .unit--simple .unit__content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.area--three .unit {
  width: 33.48214286%;
  margin-left: 8.25892857%;
  margin-right: 8.25892857%;
}
.area--masonry .unit {
  width: 47.39583333%;
  margin-left: 1.30208333%;
  margin-right: 1.30208333%;
  box-sizing: border-box;
}
.area--masonry .unit:nth-child(4n+1) {
  padding-right: 8.33333333%;
}
.area--masonry .unit:nth-child(4n+3) {
  padding-left: 8.33333333%;
}
/*# sourceMappingURL=./screen-large.css.map */